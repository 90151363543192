<template>
  <div>
    <h2>Hello {{this.$store.getters.name}},</h2>
    <div class="">
      <vsa-list>
        <vsa-item :init-active="true">
          <vsa-heading>
            Task
          </vsa-heading>
          <vsa-icon>
            <span class="open">+</span>
            <span class="close">-</span>
          </vsa-icon>
          <vsa-content>
            <widget-task/>
          </vsa-content>
        </vsa-item>
        <vsa-item>
          <vsa-heading>
            Clients
          </vsa-heading>
          <vsa-icon>
            <span class="open">+</span>
            <span class="close">-</span>
          </vsa-icon>
          <vsa-content>
            <widget-corporate/>
          </vsa-content>
        </vsa-item>
        <vsa-item>
          <vsa-heading>
            Licenses
          </vsa-heading>
          <vsa-icon>
            <span class="open">+</span>
            <span class="close">-</span>
          </vsa-icon>
          <vsa-content>
            <widget-license />
          </vsa-content>
        </vsa-item>
    </vsa-list>
    </div>
  </div>
</template>

<script>
import WidgetLicense from "@/components/admin/widget/License";
import WidgetTask from "@/components/admin/widget/Task";
import WidgetCorporate from "@/components/admin/widget/Corporate";

import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  components: {
    WidgetLicense,
    WidgetTask,
    WidgetCorporate,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },
  data() {
    return {
     
    }
  },mounted() {
    
  }
};
</script>

<style scoped>
.vsa-list{
 --vsa-max-width: default;
}
</style>

<style lang="scss" scoped>
.vsa-item {
  .open,.close{
    font-size: 25px;
  }
  &--is-active {
    .vsa-item__trigger__icon {
      .open {
        display: none;
      }

      .close {
        display: block;
      }
    }
  }
  &__trigger__icon {
    .open {
      display: block;
    }

    .close {
      display: none;
    }
  }
}
</style>